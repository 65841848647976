@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Outfit:wght@100;300;400;500;600;700;800&display=swap');

:root {
  --width: calc(100vw - (100vw - 100%));
  --width-inner: calc(80vw - (80vw - 80%));

  --font-text: "Source Sans Pro", sans-serif;
  --font-heading: "Outfit", sans-serif;

  --font-weigth-light: 300;
  --font-weigth-regular: 600;
  --font-weigt-bold: 800;

  --font-size-extra-small: 12px;
  --font-size-small: 14px;
  --font-size-medium: 24px;
  --font-size-large: 34px;

  --dark-1: #121212; /*darkest color*/
  --dark-2: #1D1D1D; /*next to darkest color*/
  --middle-1: #7D7C7A; /*middle ground grey color*/
  --light-2: #C3BFBE; /*next to lightest color*/
  --light-1: #FFFDFA; /*lightest color*/
  --signal: #f0c609;
  --outline: rgba(255,255,255,.1);

  --gradient-text: linear-gradient(89.97deg, #f0c609 1.84%, #ff33ee 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #f0c609 0%, #ff33ee 99.55%);
  
  
}

.gradient-line {
  width: 10%;
  margin: 1.5rem auto;
  display: block;
  height: 1px;
  background-image: linear-gradient(45deg, #f0c609, #ff33ee);
  
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body, html {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  color: var(--light-1);
}

body {
  background-color: var(--dark-1);
  
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  box-sizing: border-box;
  
  font-family: 'Helvetica Neue', 'Outfit', 'Equitan Sans', 'Source Sans Pro', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Source Code Pro' sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.gradient {
  color: var(--light-1);
  background-image: linear-gradient(45deg, #f0c609, #ff33ee);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.btn {
  padding: 10px 28px;
  font-size: 16px;
  text-transform: uppercase;
  background: var(--gradient-bar);
  color: var(--dark-1);
  font-weight: 600;
  cursor: pointer;
  font-family: var(--font-text);
  text-transform: uppercase;
  margin: 1rem .2rem;
  border-radius: 2px;
  opacity: 90%;
}


.btn:hover {
  opacity: 100%;
}


h1 {
  font-size: 50px;
  font-weight: 100;
  color: var(--gradient-text);
  font-family: var(--font-heading);
}

h2 {
  font-size:40px;
  font-weight: 200;
  color: var(--gradient-text);
  font-family: var(--font-heading);
}

.some-icon {
  padding-right: 10px;
  width: auto;
  height: 25px;
}

.some-container a {
  display: inline-block;
  padding: 3px;
  position: relative;
  opacity: 70%;
}

.some-container a:hover {
  color: var(--light-1);
  opacity: 100%;
}

h3 {
  text-transform: uppercase;
  font-family: var(--font-text);
  font-size: 16px;
  font-weight: 600;
  margin: 10px auto;
}

p, span, a {
  font-family: var(--font-text);
  font-size: 16px;
  font-weight: 100;
  line-height: 25px;
  letter-spacing: .5px;
}

.some-icon {
  padding-right: 10px;
}
















@media screen and (max-width:640px) {
  .content h1 {
      font-size: 3rem;
  }

  .content p {
      font-size: 1.4rem;
      margin-bottom: 1.6rem;
  }
}
