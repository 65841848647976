.leftnav-container {
    width: 250px;
    background-color: transparent;
    height: 100%;
    position: fixed;
    left: 0;
    z-index: 8;
}

ul {
    margin-top: 50px;
    display: block;
}

.leftnav-list-item {
    margin: 10px 20px;
    color: var(--light-1);
    font-size: 10px;
    color: var(--light-1);
    font-size: 14px;
    text-transform: uppercase;
    padding: 5px 10px;
    position: relative;
    font-weight: var(--font-weigth-light);
    font-size: var(--font-size-extra-small);
    font-family: var(--font-text);
    text-transform: uppercase;
}

.nav-list-item a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    left: 50%;
    display: block;
    height: 2px;
    position: absolute;
    background: var(--gradient-bar);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}