
.inner-container {
    display: grid;
    width: 80%;
    margin: 0 auto;
    height: fit-content;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 100px 0;
    color: var(--dark-1);
}

.grid-3-columns {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 3fr));
    min-width: 230px;
    max-width: 850px;
    text-align: left;
    
    
}

.grid-item {
    width: 100%;
    padding: 20px 40px;
}

h2 {
    margin-bottom: 50px;
   
}


.main-container {
    position: relative;
    display: block;
    width: 100%;
    display: grid;
    height: 100%;
    margin: auto;
    background-color: var(--light-1);
}










