label {
    color: var(--light-1);
    margin-bottom: 7px;
}

form {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin: auto auto 7px auto;
    max-width: 600px;
}

form input, textarea {
    margin-bottom: 10px;
    padding: 10px 18px;
    background: var(--dark-2);
    border-color: var(--outline);
    color: var(--light-1);
}

input {
    width: 100%;
}

textarea {
    resize: none;
}