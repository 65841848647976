.grid-container-footer {
    display: grid;
    background-color: var(--dark-2);
    padding: 20px;
    bottom: 0;
    left: 0;
    margin: auto;
    border-top: 1px solid var(--outline);
}

.grid-footer {
    display: flex;
    width: 100%;
    max-width: 1200px;
    grid-template-columns: repeat(auto-fit, minmax(230px, 3fr));
    grid-gap: 40px;
    margin: 0px auto;
    bottom: 0;
}

.grid-item-footer {
    margin: 0px auto;
    min-width: 230px;
    max-width: 400px;
}

.grid-footer a {
    color: var(--light-1);
    display: inline-block;
    position: relative;
    opacity: 70%;
}

.copyright {
    position: relative;
    bottom: 0px;
    text-align: center;
}

/*Social icons*/
.vertical-some-container {
    position: relative;
    display: inline-block;
}

.some-icon {
    padding-right: 10px;
}

.some-container a {
    display: inline-block;
    padding: 3px;
    position: relative;
    opacity: 70%;
}

.some-container a:hover {
    color: var(--light-1);
    opacity: 100%;
}
