
.hero-img {
    position: fixed;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.hero {
    height: 100vh;
    width: 100%;
    object-fit: contain;
}

.hero .content {
    text-align: center;
    position: absolute;
    margin: auto;
    width: 100%;
    height: 100%;
    top: 40vh;
}

.hero p {
    margin: 20px auto 40px auto;
}


.some-container {
    position: absolute;
    width: 25px;
    left: 20px;
    bottom: 20px;
}







