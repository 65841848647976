
.header-container {
    position: relative;
    display: block;
    width: 100%;
    margin: 3rem 0;
    display: grid;
    height: 80vh;
}

.heading{
    text-align: center;
    margin: auto;
    padding: 1rem 4rem;
    max-width: 600px;
}


