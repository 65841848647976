.reparasjon {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 6rem 1rem;
    background-image: './assets/banner-img_greyscale.jpg';
    margin: 0 auto;
    text-align: center;
}

.reparasjon-container {
    max-width: 1140px;
    margin: 50px auto;
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(3, 1fr);
}

.reparasjon-content {
    border: 1px solid var(--light-1);
    background-color: var(--dark-2);
    text-align: center;
    padding: 50px 30px;
}

.card:hover {
    background-color: #141414;
}

.card h3 {
    font-size: 1.4rem;
    padding: 1rem;
    margin: 0px auto;
}


@media screen and (max-width: 980px) {
    .card-container {
        max-width: 90%;
        margin: auto;
        grid-template-columns: 1fr;
    }
}



