.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 80px;
    background: transparent;
    font-weight: var(--font-weigth-light);
    font-size: var(--font-size-extra-small);
    font-family: var(--font-text);
    padding: 1rem;
    z-index: 10;
}

.navbar-bg {
    background-color: var(--dark-1);
    transition: .3s ease-in-out, top 0.3s, opacity 0.3s;
}


.navbar ul {
    display: table;
    max-width: 1300px;
    text-align: center;
    margin: 0 auto;
    padding: 0;
}

.navbar li {
    display: table-cell;
    position: relative;
    padding: 15px 10px;
}

.nav-list-item a, .nav-list-logo a {
    color: var(--light-1);
    font-size: 14px;
    text-transform: uppercase;
    padding: 5px 10px;
    position: relative;
}

.logo {
    width: 20px;
}

.unselected {
    opacity: 70%;
}

.selected {
    font-weight: var(--font-weigth-regular);
    opacity: 100%;
}

.nav-list-item a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    left: 50%;
    display: block;
    height: 2px;
    position: absolute;
    background: var(--gradient-bar);
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.nav-list-item a:hover:after {
    width: 82%;
    left: 9%;
}

.nav-list-item a:hover, .nav-list-logo a:hover, .grid-footer a:hover  {
    opacity: 100%;
}

.hamburger {
    display: none;
  }
  