
.section-container {
    position: relative;
    display: block;
    width: 100%;
    display: grid;
    height: 60vh;
}

.section{
    text-align: center;
    margin: auto;
    padding: 1rem 4rem;
    max-width: 600px;
}

.dark {
    background: var(--dark-2);
}

.light {
    background-color: var(--light-1);
    color: var(--dark-1);
}








